import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/UserHome.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Button, CloseButton, Tab, Nav } from 'react-bootstrap';
import config from '../config';
import { QRCodeSVG } from 'qrcode.react';
import WavesBackground from '../components/WavesBackground';
import { FaUserFriends, FaExchangeAlt, FaPlusCircle, FaListUl, FaSignOutAlt, FaShareAlt } from 'react-icons/fa';
import { FaUserPlus, FaInbox, FaPaperPlane } from 'react-icons/fa';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { FaSortAlphaDown, FaSortAlphaDownAlt } from 'react-icons/fa';
import { FaQrcode } from 'react-icons/fa';
import { FaTrash, FaCheck, FaTimes } from 'react-icons/fa';

const UserHome = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState(null);
  const [userDetails, setUserDetails] = useState({
    string_id: '',
    balance: 0,
    is_active: false,
    created_at: '',
    updated_at: '',
    nickname: '',
  });
  const [loading, setLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [usersWithNickname, setUsersWithNickname] = useState([]);
  const [friendsList, setFriendsList] = useState([]);
  const [incomingFriendRequests, setIncomingFriendRequests] = useState([]);
  const [sentFriendRequests, setSentFriendRequests] = useState([]);
  const [allUsersList, setAllUsersList] = useState([]); // Add this state variable
  const navigate = useNavigate();
  const [selectedFriend, setSelectedFriend] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [amount, setAmount] = useState(0);
  const amountInputRef = useRef(null);
  const contentRef = useRef(null);  // Ref for the content div
  const bottomBarRef = useRef(null); // Ref for the bottom bar div
  const [transactions, setTransactions] = useState({ from_transactions: [], to_transactions: [] });
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [activeKey, setActiveKey] = useState('friends');
  const [friendsLoading, setFriendsLoading] = useState(false);
  const [sendingAmount, setSendingAmount] = useState('');
  const [showCopyNotification, setShowCopyNotification] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchUserDetails = async () => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');

    if (!storedStringId || !storedPw) {
      console.error('No credentials found in local storage');
      setLoading(false);
      navigate('/');
      return;
    }
    setLoading(true); // Show loading while fetching

    try {
      const response = await fetch(`${config.baseURL_BE}/v2/users/me`, {
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'vx': config.VX
        }
      });
      if (response.ok) {
        const data = await response.json();
        setUserDetails(data);
      } else if (response.status === 401) {
        alert('Error: Unauthorized. Please check your credentials.');
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch user details', errorData);
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchFriendsData = async (callback) => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');

    if (!storedStringId || !storedPw) {
      console.error('No credentials found in local storage');
      return;
    }

    setLoading(true); // Start loading state

    try {
      // Fetch all friends-related data in parallel
      const [friendsResponse, incomingResponse, sentResponse, allUsersResponse] = await Promise.all([
        fetch(`${config.baseURL_BE}/v2/friends/${storedStringId}`, {
          headers: {
            accept: 'application/json',
            Authorization: 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
            vx: config.VX
          },
        }),
        fetch(`${config.baseURL_BE}/v2/friend_requests/received/${storedStringId}`, {
          headers: {
            accept: 'application/json',
            Authorization: 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
            vx: config.VX
          },
        }),
        fetch(`${config.baseURL_BE}/v2/friend_requests/sent/${storedStringId}`, {
          headers: {
            accept: 'application/json',
            Authorization: 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
            vx: config.VX
          },
        }),
        fetch(`${config.baseURL_BE}/v2/users/?skip=0&limit=1000`, {
          headers: {
            accept: 'application/json',
            Authorization: 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
            vx: config.VX
          },
        }),
      ]);

      // Check if all responses are successful
      if (friendsResponse.ok && incomingResponse.ok && sentResponse.ok && allUsersResponse.ok) {
        const friends = await friendsResponse.json();
        const incomingFriendRequests = await incomingResponse.json();
        const sentFriendRequests = await sentResponse.json();
        const allUsers = await allUsersResponse.json();

        // Create a set of IDs to filter out
        const excludedIds = new Set([
          storedStringId,
          ...friends.map(friend => friend.friend_id),  // Extract friend IDs
          ...incomingFriendRequests.map(request => request.user_id),  // Extract incoming request user IDs
          ...sentFriendRequests.map(request => request.friend_id),  // Extract sent request friend IDs
        ]);

        // Filter out the current user and any users in the friends, incoming, or sent requests lists
        const filteredUsers = allUsers.filter((user) => !excludedIds.has(user.string_id));

        // Set state with the fetched data
        setFriendsList(friends);
        setIncomingFriendRequests(incomingFriendRequests);
        setSentFriendRequests(sentFriendRequests);
        setAllUsersList(filteredUsers);

        // If there's a callback, pass the fetched data to it
        if (callback) {
          callback(friends, incomingFriendRequests, sentFriendRequests, filteredUsers);
        }
      } else {
        console.error('Failed to fetch friends data');
      }
    } catch (error) {
      console.error('Error fetching friends data:', error);
    } finally {
      setLoading(false); // End loading state
    }
  };

const renderFriendsPopupContent = (friendsList, incomingFriendRequests, sentFriendRequests, allUsersList, activeKey) => {
  return (
    <div className="popup-inner">
      <div className="popup-header">
        <h4>Friends</h4>
        <button className="close-btn btn-icon" onClick={() => setShowPopup(false)}>&times;</button>
      </div>
      <Tab.Container activeKey={activeKey} onSelect={handleTabSelect}>
        <Nav variant="pills" className="mb-3">
          <Nav.Item>
            <Nav.Link eventKey="friends">
              <FaUserFriends />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="incoming">
              <FaInbox />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="sent">
              <FaPaperPlane />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="all">
              <FaUserPlus />
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="friends">
            <div className="scrollable-content">
              <ul>
                {friendsList.length > 0 ? (
                  friendsList.map(friend => (
                    <li key={friend.friend_id} className="friend-item">
                      <div className="item-content">
                        <span>{friend.friend_nickname || 'Unnamed User'}</span>
                        <div>
                          <button className="btn-icon" onClick={() => handleDeleteFriend(friend.friend_id)}>
                            <FaTrash />
                          </button>
                          <button className="btn-icon" onClick={() => handleFriendSelect(friend)}>
                            <FaExchangeAlt /> {/* This button triggers the same action as selecting from Xwipers */}
                          </button>
                        </div>
                      </div>
                      <div className="cyberpunk-bar"></div>
                    </li>
                  ))
                ) : (
                  <li>No friends found</li>
                )}
              </ul>
            </div>
          </Tab.Pane>

          <Tab.Pane eventKey="incoming">
            <div className="scrollable-content">
              <ul>
                {incomingFriendRequests.length > 0 ? (
                  incomingFriendRequests.map(request => (
                    <li key={request.user_id} className="friend-item">
                      <div className="item-content">
                        <span>{request.friend_nickname || 'Unnamed User'}</span>
                        <div>
                          <button className="btn-icon" onClick={() => handleAcceptFriendRequest(request.user_id)}>
                            <FaCheck />
                          </button>
                          <button className="btn-icon" onClick={() => handleCancelReceivedFriendRequest(request.user_id)}>
                            <FaTimes />
                          </button>
                        </div>
                      </div>
                      <div className="cyberpunk-bar"></div>
                    </li>
                  ))
                ) : (
                  <li>No incoming friend requests</li>
                )}
              </ul>
            </div>
          </Tab.Pane>

          <Tab.Pane eventKey="sent">
            <div className="scrollable-content">
              <ul>
                {sentFriendRequests.length > 0 ? (
                  sentFriendRequests.map(request => (
                    <li key={request.friend_id} className="friend-item">
                      <div className="item-content">
                        <span>{request.friend_nickname || 'Unnamed User'}</span>
                        <button className="btn-icon" onClick={() => handleCancelSentFriendRequest(request.friend_id)}>
                          <FaTimes />
                        </button>
                      </div>
                      <div className="cyberpunk-bar"></div>
                    </li>
                  ))
                ) : (
                  <li>No sent friend requests</li>
                )}
              </ul>
            </div>
          </Tab.Pane>

          <Tab.Pane eventKey="all">
            <div className="scrollable-content">
              <ul>
                {allUsersList.length > 0 ? (
                  allUsersList.map(user => (
                    <li key={user.string_id} className="friend-item">
                      <div className="item-content">
                        <span>{user.nickname || 'Unnamed User'}</span>
                        <button className="btn-icon" onClick={() => handleSendFriendRequest(user.string_id)}>
                          <FaUserPlus />
                        </button>
                      </div>
                      <div className="cyberpunk-bar"></div>
                    </li>
                  ))
                ) : (
                  <li>No users found</li>
                )}
              </ul>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

  const handleTabSelect = (key) => {
    // Set the active tab key
    setActiveKey(key);

    if (key === 'friends' || key === 'incoming' || key === 'sent' || key === 'all') {
      // Clear popup content and set loading state
      setShowPopup(false);
      setPopupContent(null);

      // Fetch the updated friends data
      fetchFriendsData((friendsList, incomingFriendRequests, sentFriendRequests, allUsersList) => {
        // Once the data is fetched, update the popup with the new content and pass the active key
        setTimeout(() => {
          setPopupContent(renderFriendsPopupContent(friendsList, incomingFriendRequests, sentFriendRequests, allUsersList, key));
        }, 50); // Delay of 50ms before reopening the popup
        setShowPopup(true);
      });
    }
  };

  const handleSendFriendRequest = async (friendId) => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');

    try {
      const response = await fetch(`${config.baseURL_BE}/v2/friends`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'Content-Type': 'application/json',
          'vx': config.VX
        },
        body: JSON.stringify({ friend_id: friendId }),
      });

      if (response.ok) {
        console.log('Friend request sent successfully, updating the list');

        // Refresh the friend data and update the popup content
        fetchFriendsData((friendsList, incomingFriendRequests, sentFriendRequests, allUsersList) => {

          // Wait for a slight delay and then update the popup
          setTimeout(() => {
            setPopupContent(renderFriendsPopupContent(friendsList, incomingFriendRequests, sentFriendRequests, allUsersList, 'all'));
          }, 50); // Delay of 50ms before reopening the popup
        });
      } else {
        console.error('Failed to send friend request');
      }
    } catch (error) {
      console.error('Error sending friend request:', error);
    }
  };

  const [reloadPopup, setReloadPopup] = useState(false);

  const handleAcceptFriendRequest = async (friendId) => {
    const storedStringId = localStorage.getItem('stringId');
    const status = 'accepted';
    const storedPw = localStorage.getItem('pw');
    try {
      const response = await fetch(`${config.baseURL_BE}/v2/friends/${storedStringId}/${friendId}/${status}`, {
        method: 'PATCH',
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'vx': config.VX
        },
      });
      if (response.ok) {
        console.log('Friend request accepted, reloading popup with a delay');
        // Trigger popup close, wait for 500ms, and then reload the popup
        fetchFriendsData((friendsList, incomingFriendRequests, sentFriendRequests, allUsersList) => {

          // Wait for a slight delay and then update the popup
          setTimeout(() => {
            setPopupContent(renderFriendsPopupContent(friendsList, incomingFriendRequests, sentFriendRequests, allUsersList, 'incoming'));
          }, 50); // Delay of 50ms before reopening the popup
        });
      } else {
        console.error('Failed to accept friend request');
      }
    } catch (error) {
      console.error('Error accepting friend request:', error);
    }
  };

  const handleCancelSentFriendRequest = async (friendId) => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');

    try {
      const response = await fetch(`${config.baseURL_BE}/v2/friends/${storedStringId}/${friendId}`, {
        method: 'DELETE',
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'vx': config.VX
        },
      });

      if (response.ok) {
        console.log('Friend request canceled, reloading popup with a delay');

        // Clear popup content (close the popup)
        setPopupContent(null);

        // Fetch updated friends data after cancellation
        fetchFriendsData((friendsList, incomingFriendRequests, sentFriendRequests, allUsersList) => {
          // Wait for 500ms before reopening the popup with refreshed data
          setTimeout(() => {
            setPopupContent(renderFriendsPopupContent(friendsList, incomingFriendRequests, sentFriendRequests, allUsersList, 'sent'));
          }, 50); // Delay of 50ms before reopening popup
        });

      } else {
        console.error('Failed to cancel friend request');
      }
    } catch (error) {
      console.error('Error canceling friend request:', error);
    }
  };

  const handleCancelReceivedFriendRequest = async (friendId) => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');

    try {
      const response = await fetch(`${config.baseURL_BE}/v2/friends/${friendId}/${storedStringId}`, {
        method: 'DELETE',
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'vx': config.VX
        },
      });

      if (response.ok) {
        console.log('Friend request canceled, reloading popup with a delay');

        // Clear popup content (close the popup)
        setPopupContent(null);

        // Fetch updated friends data after cancellation
        fetchFriendsData((friendsList, incomingFriendRequests, sentFriendRequests, allUsersList) => {
          // Wait for 500ms before reopening the popup with refreshed data
          setTimeout(() => {
            setPopupContent(renderFriendsPopupContent(friendsList, incomingFriendRequests, sentFriendRequests, allUsersList, 'incoming'));
          }, 50); // Delay of 50ms before reopening popup
        });

      } else {
        console.error('Failed to cancel friend request');
      }
    } catch (error) {
      console.error('Error canceling friend request:', error);
    }
  };

  const handleDeleteFriend = async (friendId) => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');
    try {
      const response = await fetch(`${config.baseURL_BE}/v2/friends/${friendId}/${storedStringId}`, {
        method: 'DELETE',
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'vx': config.VX
        },
      });
      if (response.ok) {
        console.log('Friend deleted, reloading popup with a delay');
        // Trigger popup close, wait for 500ms, and then reload the popup
        fetchFriendsData((friendsList, incomingFriendRequests, sentFriendRequests, allUsersList) => {

          // Wait for a slight delay and then update the popup
          setTimeout(() => {
            setPopupContent(renderFriendsPopupContent(friendsList, incomingFriendRequests, sentFriendRequests, allUsersList, 'friends'));
          }, 50); // Delay of 50ms before reopening the popup
        });
      } else {
        console.error('Failed to delete friend');
      }
    } catch (error) {
      console.error('Error deleting friend:', error);
    }
  };

const handleFriendSelect = (friend) => {
  setSelectedFriend(friend);
  setAmount(0); // Reset amount when a new friend is selected
  let newAmount = 0;

  setPopupContent(
    <div className="popup-inner">
      <div className="popup-header">
        <h4>Send XPWC to {friend.friend_nickname || 'Unnamed User'}</h4>
        <button className="close-btn btn-icon" onClick={() => handleClosePopup(true)}>&times;</button>
      </div>
      <div>
        <label>Amount (Numbers Only):</label>
        <Form onSubmit={(e) => { e.preventDefault(); handleSendAmountToFriend(friend, newAmount * 100); }}>
          <Form.Group className="mb-3" controlId="inputAmount">
            <input
              type="text"
              onChange={(e) => {
                newAmount = e.target.value;
              }}
            />
          </Form.Group>
          <div className="d-flex justify-content-between mt-3">
            <Button className="btn btn-cyberpunk" variant="primary" type="submit">SUBMIT</Button>
            <button onClick={() => handleClosePopup(false)} className="btn btn-cyberpunk">CANCEL</button>
          </div>
        </Form>
      </div>
    </div>
  );

  setShowPopup(true);
};

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const [qrCodeUrl, setQrCodeUrl] = useState('');

  useEffect(() => {
    const url = `${config.baseURL_FE}/payment/verify?userId=${localStorage.getItem('stringId')}&amount=${sendingAmount}`;
    setQrCodeUrl(url);
  }, [sendingAmount]);

  useEffect(() => {
    if (showPopup) {
      console.log("Fetching friends data after popup reopened");
      fetchFriendsData(); // Refetch friends data when the popup is shown or reloadPopup is triggered
    }
  }, [showPopup, reloadPopup]);

  useEffect(() => {
    const fetchUsersWithNickname = async () => {
      const storedStringId = localStorage.getItem('stringId');
      const storedPw = localStorage.getItem('pw');

      if (!storedStringId || !storedPw) {
        console.error('No credentials found in local storage');
        return;
      }

      try {
        const response = await fetch(`${config.baseURL_BE}/v2/users/?skip=0&limit=1000`, {
          headers: {
            'accept': 'application/json',
            'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
            'vx': config.VX
          }
        });
        if (response.ok) {
          const data = await response.json();
          const usersWithNicknameFiltered = data.filter(user => user.nickname && user.string_id !== localStorage.getItem('stringId'));
          setUsersWithNickname(usersWithNicknameFiltered);
        } else if (response.status === 401) {
          loginCredentialsFailedOnAPI(navigate);
        } else {
          const errorData = await response.json();
          console.error('Failed to fetch users with nickname', errorData);
        }
      } catch (error) {
        console.error('Error fetching users with nickname:', error);
      }
    };

    fetchUsersWithNickname();
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    setShowPopup(false);
    setPopupContent(null);
    setUserDetails(prevState => ({ ...prevState, nickname: '' }));
    navigate('/');
  };

  const handleClosePopup = (returnToTransfer = false) => {
    if (returnToTransfer) {
      showTransferPopup();
    } else {
      setShowPopup(false);
      setPopupContent(null);
    }
  };

const handleFriendClick = (user) => {
  setSelectedUser(user);
  setAmount(0);
  let newAmount = 0;

  setPopupContent(
    <div className="popup-inner">
      <div className="popup-header">
        <h4>Send XPWC to {user.nickname || 'Unnamed User'}</h4>
        <button className="close-btn btn-icon" onClick={() => handleClosePopup(true)}>&times;</button>
      </div>
      <div>
        <label>Amount (Numbers Only):</label>
        <Form onSubmit={(e) => { e.preventDefault(); handleSendAmount(user, newAmount * 100); }}>
          <Form.Group className="mb-3" controlId="inputAmount">
            <input
              type="text"
              onChange={(e) => {
                newAmount = e.target.value;
              }}
            />
          </Form.Group>
          <div className="d-flex justify-content-between mt-3">
            <Button className="btn btn-cyberpunk" variant="primary" type="submit">SUBMIT</Button>
            <button onClick={() => handleClosePopup(false)} className="btn btn-cyberpunk">CANCEL</button>
          </div>
        </Form>
      </div>
    </div>
  );

  setShowPopup(true);
};

  // Fetch all users with nicknames
  const fetchUsersWithNickname = async () => {
    try {
      const response = await fetch(`${config.baseURL_BE}/v2/users/?skip=0&limit=1000`, {
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${localStorage.getItem('stringId')}:${localStorage.getItem('pw')}`),
          'vx': config.VX,
        }
      });
      if (response.ok) {
        const users = await response.json();
        const usersWithNicknameFiltered = users.filter(user => user.nickname && user.string_id !== localStorage.getItem('stringId'));
        setUsersWithNickname(usersWithNicknameFiltered);
      } else {
        console.error("Failed to fetch users");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

const showTransferPopup = () => {
  setPopupContent(
    <div className="popup-inner">
      <div className="popup-header">
        <h4>Transfer</h4>
        <button className="close-btn btn-icon" onClick={() => setShowPopup(false)}>&times;</button>
      </div>
      <div>
        <label>Search XID:</label>
        <input
          type="text"
          id="search-input"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              const filterTerm = document.getElementById('search-input').value;
              showXwipersPopup(filterTerm);
            }
          }}
        />
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button
          className="btn btn-cyberpunk"
          onClick={() => {
            const filterTerm = document.getElementById('search-input').value;
            showXwipersPopup(filterTerm);
          }}
        >
          SEARCH
        </button>
          <button
            className="btn btn-cyberpunk ms-2"
            onClick={() => showXwipersPopup()}
          >
            Show All Xwipers
          </button>
      </div>
    </div>
  );
  setShowPopup(true);
};

// Update the showXwipersPopup function to accept a filter term
const showXwipersPopup = async (filterTerm = '') => {
  await fetchUsersWithNickname(); // Fetch users from the API before displaying the popup
  const regex = new RegExp(filterTerm, 'i'); // Case-insensitive regex based on filterTerm
  const filteredUsers = usersWithNickname.filter(user => regex.test(user.nickname));

  const content = (
    <div className="popup-inner xwipers-popup">
      <div className="popup-header">
        <h4>Xwipers</h4>
        <button className="close-btn btn-icon" onClick={() => setShowPopup(false)}>&times;</button>
      </div>
      <Tab.Container defaultActiveKey="aToZ">
        <Nav variant="pills" className="mb-3">
          <Nav.Item>
            <Nav.Link eventKey="aToZ">
              <FaSortAlphaDown />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="zToA">
              <FaSortAlphaDownAlt />
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          {/* A to Z sorted list */}
          <Tab.Pane eventKey="aToZ">
            <div className="scrollable-content">
              <ul className="xwipers-list">
                {filteredUsers
                  .slice()
                  .sort((a, b) => a.nickname.localeCompare(b.nickname))
                  .map(user => (
                    <React.Fragment key={user.string_id}>
                      <li onClick={() => handleFriendClick(user)} className="clickable-friend">
                        {user.nickname}
                      </li>
                      <div className="cyberpunk-bar"></div>
                    </React.Fragment>
                ))}
              </ul>
            </div>
          </Tab.Pane>

          {/* Z to A sorted list */}
          <Tab.Pane eventKey="zToA">
            <div className="scrollable-content">
              <ul className="xwipers-list">
                {filteredUsers
                  .slice()
                  .sort((a, b) => b.nickname.localeCompare(a.nickname))
                  .map(user => (
                    <React.Fragment key={user.string_id}>
                      <li onClick={() => handleFriendClick(user)} className="clickable-friend">
                        {user.nickname}
                      </li>
                      <div className="cyberpunk-bar"></div>
                    </React.Fragment>
                ))}
              </ul>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
  setPopupContent(content);
  setShowPopup(true);
};

  const fetchTransactions = async (callback) => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');

    if (!storedStringId || !storedPw) {
      console.error('No credentials found in local storage');
      return;
    }

    setTransactionsLoading(true);

    try {
      const response = await fetch(`${config.baseURL_BE}/v2/transactions/`, {
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'vx': config.VX
        }
      });
      if (response.ok) {
        const data = await response.json();
        console.log('Fetched transactions:', data); // Log the fetched transactions
        setTransactions(() => ({
          from_transactions: data.from_transactions,
          to_transactions: data.to_transactions
        }));
        if (callback) callback(data.from_transactions, data.to_transactions);
      } else if (response.status === 401) {
        loginCredentialsFailedOnAPI(navigate);
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch transactions', errorData);
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setTransactionsLoading(false);
    }
  };

  const handleNicknameSubmit = async (nickname) => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');

    try {
      setFormLoading(true);
      console.log(config.baseURL_BE);
      const response = await fetch(`${config.baseURL_BE}/v2/users/me`, {
        method: 'PATCH',
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'Content-Type': 'application/json',
          'vx': config.VX
        },
        body: JSON.stringify({ nickname })
      });

      if (response.ok) {
        const updatedUserDetails = { ...userDetails, nickname };
        setUserDetails(updatedUserDetails);
        showTransferPopup();
      } else if (response.status === 401) {
        loginCredentialsFailedOnAPI(navigate);
      } else {
        const errorData = await response.json();
        console.error('Failed to update nickname', errorData);
      }
    } catch (error) {
      console.error('Error updating nickname:', error);
    } finally {
      setFormLoading(false);
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      const nickname = event.target.value;
      handleNicknameSubmit(nickname);
    }
  };

  // Add this helper function to copy the URL to the clipboard
  const handleShareClick = () => {
    navigator.clipboard.writeText(qrCodeUrl).then(() => {
      setShowCopyNotification(true);
      setTimeout(() => {
        setShowCopyNotification(false);
      }, 5000); // Hide the notification after 2 seconds
    }).catch(err => console.error('Failed to copy URL:', err));
  };

  const toggleInfoPopup = () => {
    setShowInfoPopup(prev => !prev);
  };

const renderInfoPopupContent = () => (
  <div className="popup-inner">
    <div className="popup-header">
      <h4>Payment Info</h4>
    <div className="close-buttons-container">
      <button className="close-btn btn-icon" onClick={handleShareClick}><FaShareAlt size={15}/></button>
      <button className="close-btn btn-icon" onClick={toggleInfoPopup}>&times;</button>
    </div>
    </div>

    <label>XPWC Amount To Pay:</label>
    <input
      type="number"
      value={sendingAmount}
      onChange={(e) => setSendingAmount(e.target.value)}
    />

     <div className="qr-code-container">
      <a href={qrCodeUrl} target="_blank" rel="noopener noreferrer">
        <QRCodeSVG value={qrCodeUrl} width="100%" height="100%" includeMargin={true} />
      </a>
    </div>

    {showCopyNotification && (
      <div className="copy-notification">Link copied to clipboard</div>
    )}

  </div>
);


  const handleSendAmount = async (user, newAmount2) => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');

    if (!storedStringId || !storedPw) {
      console.error('No credentials found in local storage');
      return;
    }

    try {
      const response = await fetch(`${config.baseURL_BE}/v2/transactions/`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'Content-Type': 'application/json',
          'vx': config.VX
        },
        body: JSON.stringify({
          amount: parseInt(newAmount2, 10),
          to_string_id: user.string_id
        })
      });

      if (response.ok) {
        const result = await response.json();
        fetchUserDetails();
      } else if (response.status === 401) {
        loginCredentialsFailedOnAPI(navigate);
      } else if (response.status === 402) {
        // Handle insufficient funds
        alert('Error: Insufficient funds. Please check your balance.');
      } else if (response.status === 403) {
        // Handle invalid amount
        alert('Error: Invalid amount. Please enter a valid amount.');
      } else {
        const errorData = await response.json();
        console.error('Failed to send amount', errorData);
      }

    } catch (error) {
      console.error('Error sending amount:', error);
    }

    handleClosePopup(); // Close the popup after sending
  };

  const handleSendAmountToFriend = async (friend, newAmount2) => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');

    if (!storedStringId || !storedPw) {
      console.error('No credentials found in local storage');
      return;
    }

    try {
      const response = await fetch(`${config.baseURL_BE}/v2/transactions/`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'Content-Type': 'application/json',
          'vx': config.VX
        },
        body: JSON.stringify({
          amount: parseInt(newAmount2, 10),
          to_string_id: friend.friend_id
        })
      });

      if (response.ok) {
        const result = await response.json();
        fetchUserDetails();
      } else if (response.status === 401) {
        loginCredentialsFailedOnAPI(navigate);
      } else if (response.status === 402) {
        // Handle insufficient funds
        alert('Error: Insufficient funds. Please check your balance.');
      } else if (response.status === 403) {
        // Handle invalid amount
        alert('Error: Invalid amount. Please enter a valid amount.');
      } else {
        const errorData = await response.json();
        console.error('Failed to send amount', errorData);
      }

    } catch (error) {
      console.error('Error sending amount:', error);
    }

    handleClosePopup(); // Close the popup after sending
  };


  useEffect(() => {
    if (showPopup && amountInputRef.current) {
      amountInputRef.current.focus();
    }
  }, [showPopup]);

  const handleDonate = async () => {
    const storedStringId = localStorage.getItem('stringId');
    const storedPw = localStorage.getItem('pw');

    if (!storedStringId || !storedPw) {
      console.error('No credentials found in local storage');
      return;
    }

    try {
      const response = await fetch(`${config.baseURL_BE}/v2/donate/?user_id=${storedStringId}`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'vx': config.VX
        },
        body: ''
      });

      if (response.ok) {
        const result = await response.json();
        fetchUserDetails();
      } else if (response.status === 401) {
        loginCredentialsFailedOnAPI(navigate);
      } else {
        const errorData = await response.json();
        console.error('Failed to donate', errorData);
      }
    } catch (error) {
      console.error('Error making donation:', error);
    }

    handleClosePopup(); // Close the popup after donating
  };

  const handleButtonClick = async (type) => {
    let content = null;
    switch (type) {
      case 'transfer':
        if (!userDetails.nickname) {
          content = (
            <div className="popup-inner">
              <div className="popup-header">
                <h4>Your Name</h4>
                <button className="close-btn btn-icon" onClick={() => handleClosePopup(false)}>&times;</button>
              </div>
              <div>
                <label>Friends will see you as:</label>
                <input type="text" id="nickname-input" onKeyPress={handleEnterKeyPress} />
              </div>
              <div className="d-flex justify-content-between mt-3">
                <button
                  className="btn btn-cyberpunk"
                  onClick={() => {
                    const nickname = document.getElementById('nickname-input').value;
                    handleNicknameSubmit(nickname);
                  }}
                  disabled={formLoading}
                >
                  {formLoading ? 'Loading...' : 'SUBMIT'}
                </button>
                <button onClick={() => handleClosePopup(false)} className="btn btn-cyberpunk">CANCEL</button>
              </div>
            </div>
          );
        } else {
          showTransferPopup();
          return;
        }
        break;
      case 'add-xwpc':
        content = (
          <div className="popup-inner">
            <div className="popup-header">
              <h4>Donate XWPC</h4>
              <button className="close-btn btn-icon" onClick={() => handleClosePopup(false)}>&times;</button>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <button className="btn btn-cyberpunk" onClick={handleDonate}>ADD XPWC</button>
              <button onClick={() => handleClosePopup(false)} className="btn btn-cyberpunk">CANCEL</button>
            </div>
          </div>
        );
        break;
case 'trxs':
  setTransactionsLoading(true); // Set transactions loading state to true
  fetchTransactions((from_transactions, to_transactions) => {
    setTransactionsLoading(false); // Set transactions loading state to false
    setPopupContent(() => (
      <div className="popup-inner">
        <div className="popup-header">
          <h4>Transactions</h4>
          <button className="close-btn btn-icon" onClick={() => handleClosePopup(false)}>&times;</button>
        </div>
        <Tab.Container defaultActiveKey="sent">
          <Nav variant="pills" className="mb-3">
            <Nav.Item>
              <Nav.Link eventKey="sent">
                <FaArrowUp /> {/* Icon for Sent Transactions */}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="received">
                <FaArrowDown /> {/* Icon for Received Transactions */}
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="sent">
              <div className="scrollable-content">
                <ul>
                  {from_transactions.map(tx => (
                    <li key={tx.id}>
                      <div>
                        XPWC: <strong>${(tx.amount / 100).toFixed(2)}</strong> <br />
                        To: <strong>{tx.to_nickname}</strong> <br />
                        Date: <strong>{new Date(tx.created_at).toLocaleString()}</strong>
                      </div>
                      <div className="cyberpunk-bar"></div>
                    </li>
                  ))}
                </ul>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="received">
              <div className="scrollable-content">
                <ul>
                  {to_transactions.map(tx => (
                    <li key={tx.id}>
                      <div>
                        From: <strong>{tx.from_nickname}</strong> <br />
                        Amount: <strong>${(tx.amount / 100).toFixed(2)}</strong> <br />
                        Date: <strong>{new Date(tx.created_at).toLocaleString()}</strong>
                      </div>
                      <div className="cyberpunk-bar"></div>
                    </li>
                  ))}
                </ul>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    ));
    setShowPopup(true);
  });

  // Show loading content while fetching transactions
  setPopupContent(() => (
    <div className="popup-inner">
      <div className="popup-header">
        <h4>Loading Transactions...</h4>
        <button className="close-btn btn-icon" onClick={() => handleClosePopup(false)}>&times;</button>
      </div>
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  ));
  setShowPopup(true);
  return;

      case 'friends':
        // Clear the popup content first
        setShowPopup(false);
        setPopupContent(null);

        // Fetch updated friends data
        fetchFriendsData((friendsList, incomingFriendRequests, sentFriendRequests, allUsersList) => {
          // Once the data is fetched, re-render the popup after a small delay
          setTimeout(() => {
            setPopupContent(renderFriendsPopupContent(friendsList, incomingFriendRequests, sentFriendRequests, allUsersList, activeKey));
          }, 50); // Delay of 50ms before reopening the popup
        });
        setShowPopup(true);
        return;

      case 'logout':
        content = (
          <div className="popup-inner">
            <h4>Are you sure?</h4>
            <p>User data might be lost if you aren't already signed in on another device.</p>
            <div className="d-flex justify-content-between">
              <button onClick={handleLogout} className="btn btn-cyberpunk">Yes, Logout</button>
              <button onClick={() => handleClosePopup(false)} className="btn btn-cyberpunk">No, Cancel</button>
            </div>
          </div>
        );
        break;
      default:
        break;
    }
    setPopupContent(content);
    setShowPopup(true);
  };

  useEffect(() => {
    const adjustContentHeight = () => {
      if (contentRef.current && bottomBarRef.current) {
        const bottomBarHeight = bottomBarRef.current.offsetHeight;
        contentRef.current.style.height = `calc(100vh - ${bottomBarHeight}px)`;
      }
    };

    adjustContentHeight();
    window.addEventListener('resize', adjustContentHeight);

    return () => {
      window.removeEventListener('resize', adjustContentHeight);
    };
  }, [reloadPopup]);

function AccountInfo({ userDetails }) {
  // Get the account creation date and today's date
  const createdDate = new Date(userDetails.created_at);
  const today = new Date();

  // Calculate the difference in days
  const timeDifference = today - createdDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return (
    <div>
      <p className="account-info mb-1">
        {daysDifference} day{daysDifference >= 2 ? 's' : ''} of using Xwiped.com
      </p>
      {/* Other account info */}
    </div>
  );
}

  function loginCredentialsFailedOnAPI(navigate) {
    console.error('Unauthorized: Check your credentials');
    alert('Error: Unauthorized. Please check your credentials.');
    navigate('/');
  }

  return (
    <div className="vh-100 position-relative flex-column d-flex">
      <WavesBackground />
      <div className="d-flex flex-column align-items-center text-white" style={{ minHeight: '100vh' }}>

      <div className="top-bar">
        <div>{userDetails.nickname && <span className="nickname">{userDetails.nickname}</span>}</div>
        <div>| XPWC</div>
        <div>: <strong>${(userDetails.balance / 100).toFixed(2)}</strong></div>
        <button className="btn btn-icon" onClick={toggleInfoPopup} style={{ marginLeft: 'auto' }}>
          <FaQrcode />
        </button>
      </div>

        {showPopup && <div className="popup">{popupContent}</div>}

      {/* Popup Rendering */}
      {showInfoPopup && (
        <div className="popup">
          {renderInfoPopupContent()}
        </div>
      )}

        <div className="container text-center special-text-container-userhome" ref={contentRef}>
          <div className="col">
            <div className="row mb-3">
              <h1 className="welcome-message mb-3">Welcome {userDetails.nickname}</h1>
              {AccountInfo({ userDetails })}
            </div>


          </div>
        </div>

        <div className="bottom-bar" ref={bottomBarRef}>
          <button className="btn btn-cyberpunk" onClick={() => handleButtonClick('transfer')}>
            <FaExchangeAlt size={24} />
            <div>TRANSFER</div>
          </button>
          <button className="btn btn-cyberpunk" onClick={() => handleButtonClick('add-xwpc')}>
            <FaPlusCircle size={24} />
            <div>ADD XPWC</div>
          </button>
          <button className="btn btn-cyberpunk" onClick={() => handleButtonClick('trxs')}>
            <FaListUl size={24} />
            <div>TRXs</div>
          </button>
          <button className="btn btn-cyberpunk" onClick={() => handleButtonClick('friends')}>
            <FaUserFriends size={24} />
            <div>FRIENDS</div>
          </button>
          <button className="btn btn-cyberpunk" onClick={() => handleButtonClick('logout')}>
            <FaSignOutAlt size={24} />
            <div>LOGOUT</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserHome;
