import React from 'react';
import UserForm from '../components/LoginUserForm';

const LogIn = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-dark text-white">
      <h1>login page</h1>
      <UserForm />
    </div>
  );
};

export default LogIn;

