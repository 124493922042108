import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./pages/Index";
import About from "./pages/about";
import SignUp from "./pages/signup";
import UserHome from "./pages/UserHome";
import Login from "./pages/login";
import ApprovePayment from "./pages/ApprovePayment";
import CookieConsent from './components/CookieConsent';
import Analytics from './components/Analytics';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  const consent = JSON.parse(localStorage.getItem('cookieConsent'));
  return (
    <Router>
      <>
        {consent && consent.statistics && <Analytics />}
        <CookieConsent />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/home" element={<UserHome />} />
          <Route path="/payment/verify" element={<ApprovePayment />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </>
    </Router>
  );
}

export default App;
