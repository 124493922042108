import React from "react";
import ThreeScene from '../components/ThreeScene';
import SignupLoginButtons from '../components/SignupLoginButtons';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Stars } from '@react-three/drei';
import 'bootstrap/dist/css/bootstrap.min.css';
import Signup_Login_Buttons from "../components/SignupLoginButtons";
import EarthBackground from '../components/RotatingSphere'; // Import the 3D Earth component
import MainPageHeading from "../components/MainPageHeading";
import CookieConsent from '../components/CookieConsent';

const Home = () => {
    return (
        <div className="bg-dark position-relative vh-100 d-flex justify-content-center align-items-center">
            <MainPageHeading />
            <EarthBackground />
            <SignupLoginButtons />
            {/* <CookieConsent /> */}
        </div>
    );
};

export default Home;

