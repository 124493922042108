import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/SignupLoginButtons.css';  // Import the custom CSS file

const SignupLoginButtons = () => {
  return (
    <div className="container button-container text-center">
      <Link to="/sign-up" className="btn btn-custom btn-custom-signup mb-3">START</Link>
      {/* <Link to="/login" className="btn btn-custom btn-custom-login mb-3">LOGIN</Link> */}
    </div>
  );
};

export default SignupLoginButtons;

