import React from "react";
 
const About = () => {
    return (
        <div>
            <h1>
                About Xwiped
            </h1>
        </div>
    );
};
 
export default About;