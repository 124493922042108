import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/MainPageHeading.css';  // Import the updated CSS file

const MainPageHeading = () => {
  useEffect(() => {
    // Only run on larger screens
    if (window.innerWidth >= 768) {
      const elements = document.querySelectorAll('.neon-text');
      elements.forEach((element) => {
        // Generate random animation duration between 2s and 4s
        const duration = Math.random() * 2 + 2; // 2s to 4s
        // Generate random animation delay between 0s and 5s
        const delay = Math.random() * 5; // 0s to 5s
        // Set CSS variables
        element.style.setProperty('--animation-duration', `${duration}s`);
        element.style.setProperty('--animation-delay', `${delay}s`);
      });
    }
  }, []);

  return (
    <div className="container main-page-heading-container text-center">
      <h1 className="neon-text">xwiped.com</h1>
      <p className="neon-text">
        Welcome to the Future <br /> Under Construction - Please do NOT use
      </p>
    </div>
  );
};

export default MainPageHeading;
