import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import config from '../config'; 

const LoginUserForm = () => {
  const [stringId, setStringId] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!stringId || !password) {
      setError('Both fields are required.');
      return;
    }

    const payload = {
      string_id: stringId,
      password: password,
    };

    try {
      const response = await fetch(`${config.baseURL_BE}/v2/users/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
          'vx': config.VX
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Network response was not ok');
      }

      const data = await response.json();
      setSuccess('User created successfully');
      console.log(data);
      
      // Navigate to another page after successful user creation
      navigate('/home');
    } catch (error) {
      setError(`Failed to create user: ${error.message}`);
      console.error('Error:', error);
    }
  };

  return (
    <div className="container mt-5">
      <h2>Create User</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formStringId">
          <Form.Label>String ID</Form.Label>
          <Form.Control
            type="text"
            value={stringId}
            onChange={(e) => setStringId(e.target.value)}
            placeholder="Enter string ID"
            required
          />
        </Form.Group>

        <Form.Group controlId="formPassword" className="mt-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit" className="mt-3">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default LoginUserForm;
