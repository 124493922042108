import React, { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import config from '../config';
import '../styles/ApprovePayment.css';

const ApprovePayment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const amountParam = queryParams.get('amount');
  const userId = queryParams.get('userId');

  // State to handle the input amount
  const [inputAmount, setInputAmount] = useState(amountParam || '');

  // State for user balance
  const [balance, setBalance] = useState(0);
  const [balanceLoading, setBalanceLoading] = useState(true);

  // State for recipient's nickname
  const [nickname, setNickname] = useState('');
  const [nicknameLoading, setNicknameLoading] = useState(true);

  // State for warning message
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    const fetchUserBalance = async () => {
      const storedStringId = localStorage.getItem('stringId');
      const storedPw = localStorage.getItem('pw');

      if (!storedStringId || !storedPw) {
        console.error('No credentials found in local storage');
        navigate('/');
        return;
      }

      try {
        const response = await fetch(`${config.baseURL_BE}/v2/users/me`, {
          headers: {
            accept: 'application/json',
            Authorization: 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
            vx: config.VX,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setBalance(data.balance / 100); // Convert balance to XPWC
        } else if (response.status === 401) {
          console.error('Unauthorized: Check your credentials');
          navigate('/');
        } else {
          const errorData = await response.json();
          console.error('Failed to fetch user balance', errorData);
        }
      } catch (error) {
        console.error('Error fetching user balance:', error);
      } finally {
        setBalanceLoading(false);
      }
    };

    fetchUserBalance();
  }, [navigate]);

  useEffect(() => {
    const fetchNickname = async () => {
      const storedStringId = localStorage.getItem('stringId');
      const storedPw = localStorage.getItem('pw');

      if (!storedStringId || !storedPw) {
        console.error('No credentials found in local storage');
        navigate('/');
        return;
      }

      try {
        const response = await fetch(`${config.baseURL_BE}/v2/user/${userId}`, {
          headers: {
            accept: 'application/json',
            Authorization: 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
            vx: config.VX,
          },
        });

        if (response.ok) {
          const data = await response.text();
          // Remove leading and trailing quotes if present
          const nickname = data.replace(/^"|"$/g, '');
          setNickname(nickname);
        } else if (response.status === 404) {
          console.error('User not found');
          setNickname('Unknown User');
        } else if (response.status === 401) {
          console.error('Unauthorized: Check your credentials');
          navigate('/');
        } else {
          const errorData = await response.json();
          console.error('Failed to fetch nickname', errorData);
        }
      } catch (error) {
        console.error('Error fetching nickname:', error);
      } finally {
        setNicknameLoading(false);
      }
    };

    fetchNickname();
  }, [navigate, userId]);

  // Calculate future balance
  const parsedInputAmount = parseFloat(inputAmount);
  const futureBalance =
    !isNaN(parsedInputAmount) && parsedInputAmount >= 0
      ? balance - parsedInputAmount
      : balance;

  // Determine if confirm button should be disabled
  const isConfirmDisabled =
    isNaN(parsedInputAmount) || parsedInputAmount <= 0 || parsedInputAmount > balance;

  // Show warning if amount exceeds balance
  useEffect(() => {
    if (parsedInputAmount > balance) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  }, [parsedInputAmount, balance]);

  const handleConfirm = async () => {
    try {
      const storedStringId = localStorage.getItem('stringId');
      const storedPw = localStorage.getItem('pw');

      // Determine the amount to send
      const amountToSend = parseFloat(inputAmount);

      if (isNaN(amountToSend) || amountToSend <= 0) {
        alert('Please enter a valid amount.');
        return;
      }

      if (amountToSend > balance) {
        alert('Insufficient balance to complete this transaction.');
        return;
      }

      const response = await fetch(`${config.baseURL_BE}/v2/transactions/`, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          Authorization: 'Basic ' + btoa(`${storedStringId}:${storedPw}`),
          'Content-Type': 'application/json',
          vx: config.VX,
        },
        body: JSON.stringify({
          amount: parseInt(100 * amountToSend, 10),
          to_string_id: userId,
        }),
      });

      if (response.ok) {
        window.location.href = '/home';
      } else if (response.status === 401) {
        console.error('Unauthorized: Check your credentials');
        navigate('/');
      } else if (response.status === 402) {
        alert('Error: Insufficient funds. Please check your balance.');
      } else {
        const errorData = await response.json();
        console.error('Failed to send amount', errorData);
      }
    } catch (error) {
      console.error('Error sending amount:', error);
    }
  };

  return (
    <div className="approve-payment-container">
      <div className="approve-payment-content">
        <div className="approve-payment-title-container">
          <h1 className="approve-payment-title">Payment Approval</h1>
        </div>
        {balanceLoading ? (
          <div className="approve-payment-loading">Loading balance...</div>
        ) : (
          <div className="approve-payment-balance-info">
            <div>
              <strong>Current Balance:</strong> {balance.toFixed(2)} XPWC
            </div>
            <div>
              <strong>Balance after Payment:</strong>{' '}
              {futureBalance >= 0 ? (
                futureBalance.toFixed(2)
              ) : (
                <span className="approve-payment-negative-balance">
                  {futureBalance.toFixed(2)}
                </span>
              )}{' '}
              XPWC
            </div>
          </div>
        )}
        <div className="approve-payment-details">
          {/* Amount Section */}
          {(!amountParam || parseFloat(amountParam) === 0) ? (
            <div className="approve-payment-amount-input-container">
              <label htmlFor="amount" className="approve-payment-amount-input-label">
                Amount:
              </label>
              <input
                type="number"
                id="amount"
                className="approve-payment-amount-input-field"
                placeholder="Enter amount"
                value={inputAmount}
                onChange={(e) => setInputAmount(e.target.value)}
              />
            </div>
          ) : (
            <div className="approve-payment-amount-container">
              <div className="approve-payment-amount">
                <div className="approve-payment-amount-value">{amountParam}</div>
                <div className="approve-payment-currency">XPWC</div>
              </div>
            </div>
          )}

          {/* Nickname Section */}
          {!nicknameLoading && (
            <div className="approve-payment-nickname-container">
              <div className="approve-payment-nickname">{nickname}</div>
            </div>
          )}
        </div>

        {/* Warning Message */}
        {showWarning && (
          <div className="approve-payment-warning-message">
            Amount exceeds current balance.
          </div>
        )}

        {/* Footer */}
        <div className="approve-payment-footer">
          {/* User ID at the bottom */}
          <div className="approve-payment-user-id">
            <strong>User ID:</strong> {userId}
          </div>

          <div className="approve-payment-button-container">
            {/* Swapped the buttons here */}
            <button
              className={`approve-payment-btn approve-payment-btn-confirm ${
                isConfirmDisabled ? 'approve-payment-btn-disabled' : ''
              }`}
              onClick={handleConfirm}
              disabled={isConfirmDisabled}
            >
              CONFIRM
            </button>
            <Link to="/home" className="approve-payment-btn approve-payment-btn-cancel">
              CANCEL
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovePayment;
