import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/CookieConsent.css';
import { Link } from 'react-router-dom';

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [consentPreferences, setConsentPreferences] = useState({
    necessary: true, // Necessary cookies are always enabled
    preferences: false,
    statistics: false,
    marketing: false,
  });
  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent) {
      setConsentPreferences(JSON.parse(consent));
    } else {
      setShowBanner(true);
    }
  }, []);

  const handleAcceptAll = () => {
    const consent = {
      necessary: true,
      preferences: true,
      statistics: true,
      marketing: true,
    };
    localStorage.setItem('cookieConsent', JSON.stringify(consent));
    setConsentPreferences(consent);
    setShowBanner(false);
  };

  const handleSavePreferences = () => {
    localStorage.setItem('cookieConsent', JSON.stringify(consentPreferences));
    setShowBanner(false);
    setShowSettings(false);
  };

  const handlePreferenceChange = (category) => {
    setConsentPreferences((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  return (
    <>
      {showBanner && (
        <div className="cookie-consent-container">
          <p>
            We use cookies to personalize content and ads, to provide social media features and to analyze our traffic. Read our{' '}
            <Link to="/privacy-policy" className="privacy-link">
              Privacy Policy
            </Link>
            .
          </p>
          <div className="button-group">
            <button className="btn btn-custom" onClick={handleAcceptAll}>
              Accept All
            </button>
            <button className="btn btn-custom" onClick={() => setShowSettings(true)}>
              Manage Preferences
            </button>
          </div>
        </div>
      )}

      {showSettings && (
        <div className="cookie-settings-modal">
          <div className="cookie-settings-content">
            <h2>Cookie Preferences</h2>
            <form>
              <div className="form-group">
                <label>
                  <input type="checkbox" checked disabled />
                  Necessary Cookies (Always Active)
                </label>
                <p>These cookies are essential for the website to function.</p>
              </div>
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={consentPreferences.preferences}
                    onChange={() => handlePreferenceChange('preferences')}
                  />
                  Preferences Cookies
                </label>
                <p>These cookies allow the website to remember choices you make.</p>
              </div>
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={consentPreferences.statistics}
                    onChange={() => handlePreferenceChange('statistics')}
                  />
                  Statistics Cookies
                </label>
                <p>These cookies help us understand how visitors interact with our website.</p>
              </div>
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={consentPreferences.marketing}
                    onChange={() => handlePreferenceChange('marketing')}
                  />
                  Marketing Cookies
                </label>
                <p>These cookies are used to track visitors across websites.</p>
              </div>
            </form>
            <div className="button-group">
              <button className="btn btn-custom" onClick={handleSavePreferences}>
                Save Preferences
              </button>
              <button className="btn btn-custom" onClick={() => setShowSettings(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
