// src/components/RotatingSphere.js
import React, { useRef, useEffect } from 'react';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import getStarfield from './getStarfield';
import { getFresnelMat } from './getFresnelMat';

function Earth() {
  const earthGroup = useRef();
  const textureLoader = useLoader(THREE.TextureLoader, [
    '/textures/00_earthmap1k.jpg',
    '/textures/01_earthbump1k.jpg',
    '/textures/02_earthspec1k.jpg',
    '/textures/03_earthlights1k.jpg',
    '/textures/04_earthcloudmap.jpg',
    '/textures/05_earthcloudmaptrans.jpg',
  ]);

  const [earthMap, bumpMap, specMap, lightsMap, cloudsMap, cloudsAlphaMap] = textureLoader;

  // Create the Earth materials
  const earthMaterial = new THREE.MeshPhongMaterial({
    map: earthMap,
    specularMap: specMap,
    bumpMap: bumpMap,
    bumpScale: 0.04,
    transparent: true, // Enable transparency for fade-in effect
    opacity: 0,        // Start fully transparent
  });

  const lightsMaterial = new THREE.MeshBasicMaterial({
    map: lightsMap,
    blending: THREE.AdditiveBlending,
    transparent: true,
    opacity: 0,  // Start fully transparent
  });

  /*  const cloudsMaterial = new THREE.MeshStandardMaterial({
      map: cloudsMap,
      transparent: true,
      opacity: 0.5,
      blending: THREE.AdditiveBlending,
      alphaMap: cloudsAlphaMap,
    });*/

  let fresnelMaterial = getFresnelMat({ rimHex: 0x0088ff, facingHex: 0x000000, transp: 0.0 });
  fresnelMaterial.transparent = true;
  fresnelMaterial.opacity = 0; // Start fully transparent

  useFrame((state) => {
    const elapsed = state.clock.getElapsedTime();
    const fadeInDuration = 10; // 20 seconds fade-in

    earthGroup.current.rotation.y += 0.001;
    // Gradually increase opacity over time (up to 1)
    const newOpacity = Math.min(elapsed / fadeInDuration, 1);
    earthMaterial.opacity = newOpacity;
    lightsMaterial.opacity = newOpacity;
    //fresnelMaterial.opacity = newOpacity;
    fresnelMaterial = getFresnelMat( { rimHex: 0x0088ff, facingHex: 0x000000, transp: Math.min(elapsed / fadeInDuration, 1)});
  });


  return (
    <group ref={earthGroup} rotation={[0, 0, -23.4 * Math.PI / 180]}>
      {/* Earth Mesh */}
      <mesh geometry={new THREE.IcosahedronGeometry(1, 12)} material={earthMaterial} />

      {/* Earth lights */}
      <mesh geometry={new THREE.IcosahedronGeometry(1, 12)} material={lightsMaterial} />

      {/* Clouds */}
      {/* <mesh geometry={new THREE.IcosahedronGeometry(1, 12)} material={cloudsMaterial} scale={1.003} /> */}

      {/* Fresnel Effect (Glow) */}
      <mesh geometry={new THREE.IcosahedronGeometry(1, 12)} material={fresnelMaterial} scale={1.003} />
    </group>
  );
}

function Scene() {
  const stars = getStarfield({ numStars: 2000 });
  const sunLightRef = useRef();
  const radius = 50; // Radius for the Sun's orbit
  let angle = Math.PI * 1.5; // Start Sun behind the Earth (180 degrees)
  
  // Animate the Sun rotating around the Earth
  useFrame(({ clock }) => {
    angle = Math.PI * 1.5 + clock.getElapsedTime() * 0.3; // Adjust speed of Sun's orbit
    const x = radius * Math.cos(angle); // Sun's x position
    const z = radius * Math.sin(angle); // Sun's z position

    if (sunLightRef.current) {
      sunLightRef.current.position.set(x, 0.5, z); // Update Sun's position

      // Gradually increase Sunlight intensity
      // const daylightFactor = Math.max(0, Math.sin(angle - Math.PI)); // Calculates how much the sun is in the 'day' region
      //sunLightRef.current.intensity = 0.1 + 0.1 * clock.getElapsedTime(); // Increase intensity as Sun rises
      sunLightRef.current.intensity = 5; // Increase intensity as Sun rises

      // Adjust shadow softness based on Sun position and intensity
      // sunLightRef.current.shadow.camera.near = 0.5;
      // sunLightRef.current.shadow.camera.far = 50;
      // sunLightRef.current.shadow.bias = -0.0001;
      // sunLightRef.current.shadow.camera.near = 5;
      // sunLightRef.current.shadow.camera.far = 5;
      // sunLightRef.current.shadow.bias = 5;
    }
  });

  // Gradually fade in the stars as well
  useFrame((state) => {
    const elapsed = state.clock.getElapsedTime();
    const fadeInDuration = 20; // 20 seconds fade-in

    const newOpacity = Math.min(elapsed / fadeInDuration, 1);
    stars.material.opacity = newOpacity; // Stars fade in gradually
  });

  return (
    <>
      <Earth />
      <primitive object={stars} />
      <directionalLight
        ref={sunLightRef}
        color={0xf0ffff}
        intensity={0} // Start with low intensity (night)
        castShadow
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
      />
    </>
  );
}

export default function EarthBackground() {
  useEffect(() => {
    function handleWindowResize() {
      window.location.reload();
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
      <Canvas
        camera={{ fov: 75, position: [0, 0, 3] }}
        style={{ backgroundColor: 'black' }}
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        zIndex={-1}
      // shadows // Enable shadows
      >
      <Scene />
      <OrbitControls />
    </Canvas>
  );
}

