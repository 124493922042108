import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import '../styles/ThreeScene.css';

const ThreeScene = () => {
    const mountRef = useRef(null);

    useEffect(() => {
        const scene = new THREE.Scene();

        // Adjust camera position for a better view of the model
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        camera.position.set(0, 2, 20); // Changed to give a better view of the model

        const renderer = new THREE.WebGLRenderer();
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.shadowMap.enabled = true;  // Enable shadow mapping
        if (mountRef.current) {
            mountRef.current.appendChild(renderer.domElement);
        }

        // Restore skybox
        const loader = new THREE.TextureLoader();
        const texturePath = '/textures/';
        const skyboxTextures = {
            'right': `${texturePath}skybox/cube_right.png`,
            'left': `${texturePath}skybox/cube_left.png`,
            'top': `${texturePath}skybox/cube_up.png`,
            'bottom': `${texturePath}skybox/cube_down.png`,
            'front': `${texturePath}skybox/cube_back.png`,
            'back': `${texturePath}skybox/cube_front.png`
        };

        const materials = Object.values(skyboxTextures).map(texture => {
            return new THREE.MeshBasicMaterial({
                map: loader.load(texture),
                side: THREE.BackSide
            });
        });

        const skyboxGeometry = new THREE.BoxGeometry(500, 500, 500);
        const skybox = new THREE.Mesh(skyboxGeometry, materials);
        scene.add(skybox);

        // Load GLTF/GLB model
        const gltfLoader = new GLTFLoader();
        gltfLoader.load('/models/bridge.glb', (gltf) => {
            const model = gltf.scene;

            // Increase the model scale
            model.scale.set(17, 17, 17); // Increased scale for better visibility

            // // Enable shadows for the model
            // model.traverse((child) => {
            //     if (child.isMesh) {
            //         child.castShadow = true;  // Model casts shadows
            //         child.receiveShadow = true; // Model receives shadows
            //     }
            // });

            scene.add(model);

            // Add a directional light to simulate sunlight
            const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
            directionalLight.position.set(10, 10, 10);
            directionalLight.castShadow = true;  // Enable shadows for this light
            scene.add(directionalLight);

            // Adjust shadow properties
            directionalLight.shadow.mapSize.width = 1024; // Shadow map resolution
            directionalLight.shadow.mapSize.height = 1024;
            directionalLight.shadow.camera.near = 0.5; // Camera near plane
            directionalLight.shadow.camera.far = 50; // Camera far plane

            // Add ambient light to brighten the whole scene
            const ambientLight = new THREE.AmbientLight(0x404040, 2); // Higher intensity
            scene.add(ambientLight);

            const orbitControls = new OrbitControls(camera, renderer.domElement);

            let autoRotate = true;

            function animate() {
                requestAnimationFrame(animate);

                if (autoRotate) {
                    model.rotation.y += 0.01;
                }

                orbitControls.update();
                renderer.render(scene, camera);
            }

            animate();

            orbitControls.addEventListener('start', function () {
                autoRotate = false;
            });

            orbitControls.addEventListener('end', function () {
                autoRotate = true;
            });

            window.addEventListener('resize', () => {
                camera.aspect = window.innerWidth / window.innerHeight;
                camera.updateProjectionMatrix();
                renderer.setSize(window.innerWidth, window.innerHeight);
            });
        });

        return () => {
            if (mountRef.current) mountRef.current.removeChild(renderer.domElement);
        };
    }, []);

    return (
        <div>
            <div ref={mountRef} />
        </div>
    );
};

export default ThreeScene;
