// src/MatrixScreensaver.js
import React, { useEffect, useRef, useState } from 'react';

const MatrixScreensaver = () => {
  const canvasRef = useRef(null);
  const [mouseMoved, setMouseMoved] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const fontSize = 20;
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!@#$%^&*()_+-=[]{}|;\':",./<>?';

    let columns;
    let drops;

    const initialize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      columns = Math.floor(canvas.width / fontSize);
      drops = Array.from({ length: columns }, () => 0);
    };

    const draw = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = '#0F0';
      ctx.font = `${fontSize}px monospace`;

      for (let i = 0; i < drops.length; i++) {
        const text = characters[Math.floor(Math.random() * characters.length)];
        const x = i * fontSize;
        const y = drops[i] * fontSize;
        ctx.fillText(text, x, y);

          if (y > canvas.height && Math.random() > 0.975) {
            drops[i] = 0;
          }
          drops[i]++;
      }

      if (mouseMoved) {
        setMouseMoved(false);
      }
    };

    initialize();

    const interval = setInterval(draw, 33);

    const handleMouseMove = () => {
      setMouseMoved(true);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('resize', initialize);

    return () => {
      clearInterval(interval);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('resize', initialize);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{ display: 'block', background: 'black', position: 'fixed', top: 0, left: 0 }}
    />
  );
};

export default MatrixScreensaver;
