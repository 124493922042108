// PrivacyPolicy.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/PrivacyPolicy.css'; // Create this CSS file for styling

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-page">
            <div className="privacy-policy-container mt-5 text-white">
                <h1>Privacy Policy</h1>
                <p>
                    This Privacy Policy explains how we collect, use, and share your personal data when you use our website and services.
                </p>
                <h2>1. Introduction</h2>
                <p>
                    Your privacy is important to us. This policy outlines your rights under the GDPR and other applicable laws.
                </p>
                <h2>2. Data We Collect</h2>
                <p>
                    - Personal identification information (Name, email address, phone number, etc.)
                    <br />
                    - Usage data and analytics
                </p>
                <h2>3. How We Use Your Data</h2>
                <p>
                    We use your data to provide and improve our services, communicate with you, and comply with legal obligations.
                </p>
                <h2>4. Legal Basis for Processing</h2>
                <p>
                    We process your personal data based on your consent, to fulfill a contract, or for our legitimate business interests.
                </p>
                <h2>5. Your Rights</h2>
                <p>
                    Under the GDPR, you have the right to access, rectify, erase, restrict, and object to the processing of your personal data.
                </p>
                <h2>6. Cookies and Tracking Technologies</h2>
                <p>
                    We use cookies to enhance your experience. You can manage your preferences through our cookie consent banner.
                </p>
                <h2>7. Data Security</h2>
                <p>
                    We implement appropriate security measures to protect your personal data from unauthorized access.
                </p>
                <h2>8. Contact Us</h2>
                <p>
                    If you have any questions about this Privacy Policy, please contact us at [your contact information].
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
